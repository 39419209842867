import { PanelNavigation } from '/features/buildingBlocks/PanelNavigation'
import { useScrollSpy } from '/machinery/useScrollSpy'
import { useElementSize } from '@kaliber/use-element-size'
import { Panel } from '/features/buildingBlocks/Panel'
import { availableTileStyleContexts } from '/features/buildingBlocks/tile/Tile'

import styles from './Panels.css'

export function Panels({ panels }) {
  const { refs: scrollSpyRefs, index } = useScrollSpy({ chapters: panels.length, offset: 0.5 })

  return (
    <div className={styles.component}>
      <PanelNavigation
        {...{ index, panels }}
        layoutClassName={styles.navigationLayout}
      />

      {panels.map(({ title, content, backgroundImage, backgroundColor, tiles }, i) => {
        return (
          <Container key={i} {... { title, content, backgroundImage, backgroundColor, tiles, i, scrollSpyRefs }} />
        )
      })}
    </div>
  )
}

function Container({ title, content, backgroundImage, backgroundColor, tiles, i, scrollSpyRefs }) {
  const styleContext = backgroundImage ? 'blue' : backgroundColor
  const availableStyleContexts = availableTileStyleContexts.filter(x => x !== styleContext)

  return (
    <React.Fragment>
      <div ref={scrollSpyRefs[i]} id={`panel-${i}`} key={i} className={styles.scrollSpy} />
      <Section
        dataX={`section-panel-${i}`}
        dataStyleContext={styleContext}
        layoutClassName={styles.sectionLayout}
      >
        <Panel
          contentPlaceAlignment={i % 2 === 0 ? 'right' : 'left'}
          {... { title, content, backgroundImage, tiles, availableStyleContexts }}
        />
      </Section>
    </React.Fragment>
  )
}

function Section({ children, layoutClassName, dataStyleContext, dataX }) {
  const { ref, size } = useElementSize()

  return (
    <section
      data-x={dataX}
      data-style-context={dataStyleContext}
      style={{ top: `calc(${-size.height}px + 100vh)` }}
      className={cx(styles.componentSection, layoutClassName)}
      {...{ ref }}
    >
      {children}
    </section>
  )
}
