import { Icon } from '/features/buildingBlocks/Icon'
import { useSpring, animated } from 'react-spring'
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
} from '@floating-ui/react'

import reactangleIcon from '/images/icons/rectangle.raw.svg'
import styles from './PanelNavigation.css'

export function PanelNavigation({ index, panels, layoutClassName }) {
  const currentPanelBackgroundColor = panels[index]?.backgroundImage ? 'blue' : panels[index]?.backgroundColor
  return (
    <div className={cx(styles.component, layoutClassName)}>
      {panels.map((x, i) => (
        <Bullet
          dataStyleContext={currentPanelBackgroundColor}
          label={x.title}
          dataX='click-to-goto-panel'
          active={index === i}
          key={i}
          index={i}
        />
      ))}
    </div>
  )
}

function Bullet({ active, label, dataStyleContext, index, dataX }) {
  const [openTooltip, setOpenTooltip] = React.useState(false)
  const floating = useFloatingUI({ open: openTooltip, setOpen: setOpenTooltip, placement: 'left', offsetValue: 15 })
  const spring = useSpring({
    opacity: openTooltip ? 1 : 0
  })
  const style = {
    ...spring,
    ...floating.floatingStyles
  }
  return (
    <div data-style-context={dataStyleContext} className={styles.componentBullet}>
      <a
        {...floating.getReferenceProps()}
        aria-label={label}
        href={`#panel-${index}`}
        ref={floating.refs.setReference}
        className={cx(styles.button, active && styles.active)}
        data-x={dataX}
      />
      <animated.div
        {... floating.getFloatingProps()}
        ref={floating.refs.setFloating}
        data-style-context={dataStyleContext}
        className={styles.tooltip}
        {... { style }}
      >
        <div className={styles.label}>{label}</div>
        <Icon layoutClassName={styles.iconLayout} icon={reactangleIcon} />
      </animated.div>
    </div>
  )
}

function useFloatingUI({ open, setOpen, placement, offsetValue }) {
  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange: setOpen,
    placement,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(offsetValue),
      flip({
        fallbackAxisSideDirection: 'start'
      }),
      shift()
    ]
  })

  const hover = useHover(context, { move: false })
  const focus = useFocus(context)
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'tooltip' })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role
  ])

  return { refs, floatingStyles, getFloatingProps, getReferenceProps }
}
