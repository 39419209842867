import { sequence } from '@kaliber/math'

export function useScrollSpy({ chapters, offset }) {
  const [refs, setRefs] = React.useState(() => sequence(chapters).map(() => React.createRef()))
  const [index, setIndex] = React.useState(-1)

  React.useEffect(() => {
    setRefs(sequence(chapters).map(() => React.createRef()))
  }, [chapters])

  React.useEffect(() => {
    let debounce = null
    const measurements = new WeakMap()

    window.addEventListener('scroll', handleScroll)
    handleScroll()
    return () => window.removeEventListener('scroll', handleScroll)

    function handleScroll() {
      if (!debounce) {
        refs.forEach(ref => {
          measurements.set(ref, ref?.current?.getBoundingClientRect().top)
        })
      } else {
        clearTimeout(debounce)
        debounce = setTimeout(() => {
          debounce = null
        }, 100)
      }

      const activeRef = [...refs].reverse().find(ref => measurements.get(ref) < (offset * window.innerHeight))
      setIndex(refs.indexOf(activeRef))
    }
  }, [refs, offset])

  return { refs, index }
}
